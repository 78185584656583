<template>
  <el-main class="rh-medform">
    <form-fpregradedit :ismeddetail="true" :routetolist="fprRouteToList" />
  </el-main>
</template>
<script>
import FormFpregradedit from "@/components/rh/formulaires/FormFpregradedit";

export default {
  components: { "form-fpregradedit": FormFpregradedit },
  computed: {
    fprRouteToList() {
      return {
        name: "med_fpregrads",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
